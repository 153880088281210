(function (a, b) {
    (typeof define === 'function' && define.amd) ? define(['jquery'], b): a.b = b(jQuery);
})(typeof window !== undefined ? window : this, function ($) {
    if (Object.prototype.toString.call($) !== "[object Function]") {
        console.error('网站还没有引入jQuery');
        return false
    }

    $.fn.segment = function (settings) {
        if (this.length < 0) return false;
        return this.each(function () {
            var $self = $(this);
            if ($.type(settings) !== 'string') {
                instance = new Segment($self, settings);
                instance.initial();
            } else {
                console.error('配置参数格式不正确')
            }
        })
    }
    var Segment = function ($element, settings) {
        this.node = {
            elem: $element
        };
        this.index = 0;
        this.count = 0;
        this.width = 0;
        this.params = $.extend({}, this.defaults, settings);
    }
    var proto = Segment.prototype;
    proto.defaults = {
        events: 'click', // 切换方式click, mouseover
        effect: 'hide', // 切换效果hide, fade, slide
        target: false,
        selected: true,
        markClass: 'active', // 切换添加标志
        itapClass: '.segment-ibtn', // 切换触发按钮
        itemClass: '.segment-item', // 切换内容部分
        after: function () {}, // 切换后回调
        before: function () {} // 切换前回调
    };
    proto.initial = function () {
        var node = this.node;
        var params = this.params;
        var effect = node.elem.data('effect') || '';

        effect ? params.effect = effect : null;
        node.elem.addClass('segment-' + params.effect);
        node.ibtn = node.elem.find(params.itapClass);
        node.item = node.elem.find(params.itemClass);
        node.tabs = node.ibtn.eq(0).parent();
        node.pane = node.item.eq(0).parent();
        this.count = node.item.length;

        this.setSize();
        this.handleEvent();
        params.selected && this.handleEffect(node.ibtn.eq(0));
    };
    proto.setSize = function () {
        var node = this.node;
        var params = this.params;
        var current = node.item.eq(0);
        var width = current.outerWidth();
        var height = current.outerHeight();

        if (params.effect === 'fade') {
            node.pane.height(height).css({
                position: 'relative'
            });
            node.item.width('100%').css({
                display: 'block',
                position: 'absolute',
                opacity: 0,
                top: 0,
                left: 0
            })
        }

        if (params.effect === 'slide') {
            this.width = width
            node.roll = $('<div style="width: ' + (width * this.count) + 'px;"></div>').appendTo(node.pane);
            node.item.appendTo(node.roll);
            node.roll.height(height);
            node.item.width(width).height(height).css({
                float: 'left',
                display: 'block'
            });
        }
    };
    proto.handleEvent = function () {
        var that = this
        var node = that.node;
        var params = that.params;

        node.ibtn.bind(params.events, function (event) {
            var self = $(this);
            var href = self.attr('href')  || self.data('href');
            var target = self.attr('target') || self.data('target');
            var isTarget = ( !!target ? true : params.target);
            event.preventDefault()
            that.index = self.index()
            if (!href || /javascript/g.test(href)) {
                that.handleEffect(self)
            } else {
                isTarget ? window.open(href, target) : window.location.href = href
            }
        })
    };
    proto.handleEffect = function (elem) {
        var node = this.node;
        var params = this.params;
        var current = node.item.eq(this.index);

        params.before(elem, this.index, node);

        if (!params.selected && elem.hasClass('active')) {
            return elem.removeClass('active')
        }

        switch (params.effect) {
            case 'hide':
                current.fadeIn(400).siblings().hide();
                break;
            case 'fade':
                node.item.stop().animate({ opacity: 0 });
                current.stop().animate({ opacity: 1 });
                break;
            default:
                node.pane.animate({ scrollLeft: this.width * this.index }, 400);
        }
        params.after(elem, this.index, node)
        this.addMark()
    };
    proto.addMark = function () {
        var node = this.node;
        var mark = this.params.markClass;
        node.ibtn.eq(this.index).addClass(mark).siblings().removeClass(mark);
        node.item.eq(this.index).addClass(mark).siblings().removeClass(mark);
    };
});